/** @jsx jsx */
import React, { useContext, useState } from "react";
import { Layout, Drawer, Menu, Typography } from "antd";
import { FiSearch } from "react-icons/fi";
import { IoIosOptions } from "react-icons/io";

import { headerCss, sierraCss } from "./header.styles";
import Settings from "../settingsApp/settings.component";
import { contentPages, themeColorPalettes } from "../../utils/utilities";
import { AiFillHdd, AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useScreenSize } from "../../utils/customHooks";
import { BsFillFolderFill } from "react-icons/bs";
import { FaUsers, FaSuitcase } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";
import { TiUserAdd } from "react-icons/ti";
import AppContext from "../../utils/context";
import { jsx } from "@emotion/react"
import { GiOpenBook } from "react-icons/gi";
const EasycoachLogo = require("../../imgs/easycoach_logo.jpeg");
const TripsLogo = require("../../imgs/trips_logo.svg");
const SmartcoachLogo = require("../../imgs/smartcoach_logo.webp");

const { Header } = Layout;
const { Text } = Typography;

interface IHeaderProps {
  setContentForm: (arg: number) => void;
  theme: string;
  contentForm: number;
  collapse: boolean;
}

const HeaderComponent: React.FC<IHeaderProps> = ({
  contentForm,
  theme,
  setContentForm,
  collapse,
}) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [screenSize] = useScreenSize();

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  const handleMenuSelect = (key: string) => {
    if (key === '11') {
      setContentForm(contentPages["paidBookings"])
    } else if (key === '10') {
      setContentForm(contentPages["paidNoTickets"])
    } else if (key === '3') {
      setContentForm(contentPages["unconfirmedBookings"])
    } else if (key === '4') {
      setContentForm(contentPages["allBookings"])
    } else if (key === '2') {
      setContentForm(contentPages["todayBookings"])
    } else if (key === '5') {
      setContentForm(contentPages["yesterDayBookings"])
    } else if (key === '7') {
      setContentForm(contentPages["analysis"])
    } else if (key === '14') {
      setContentForm(contentPages["tripsLanding"])
    } else if (key === '6'){
      navigate("/login")
    } else {
      
    }
  }

  const handleEasyMenuSelect = (key: string) => {
    if (key === '13') {
      setContentForm(contentPages["easyCoachLanding"])
    } else if (key === '11') {
      setContentForm(contentPages["paidBookings"])
    } else if (key === '10') {
      setContentForm(contentPages["paidNoTickets"])
    } else if (key === '3') {
      setContentForm(contentPages["unconfirmedBookings"])
    } else if (key === '6'){
      navigate("/login")
    } else {
      
    }
  }

  return (
    <Header
      css={
        theme === "Sierra" 
        ? sierraCss 
        : headerCss
      }
      style={{
        padding: 0,
        backgroundColor:
          theme === "default"
            ? themeColorPalettes[theme ? theme : "default"].sideBarColor
            : themeColorPalettes[theme ? theme : "default"].sideBarColor,
        color: themeColorPalettes[theme ? theme : "default"].iconColor,
      }}
    >
      <div className="drawer-button">
        <IoIosOptions onClick={showDrawer} />
      </div>
      <div
        id="drawer-id"
        className={theme === "Sierra" ? "sierra-theme" : "default-theme"}
      >
        <Drawer
          placement="right"
          closable={true}
          width={250}
          onClose={closeDrawer}
          open={visibleDrawer}
          getContainer={() =>
            document.getElementById("drawer-id") as HTMLElement
          }
        >
            {screenSize.dynamicWidth < 1280 ? (
              context.profileInfo.assigned_role === "easycoach_user" ? (
                <Menu
                  mode="inline"
                  onClick={(e) => {
                    handleEasyMenuSelect(e.key)
                  }}
                  items={[
                    {
                      key: '13',
                      icon: <BsFillFolderFill />,
                      label: 'Home'
                    },
                    {
                      key: '11',
                      icon: <AiFillHome />,
                      label: 'Paid'
                    },
                    {
                      key: '10',
                      icon: <TiUserAdd />,
                      label: 'Paid No Tickets'
                    },
                    {
                      key: '3',
                      icon: <BsFillFolderFill />,
                      label: 'Unconfirmed Bookings'
                    },
                    {
                      key: '6',
                      icon:  <IoMdExit />,
                      label: 'Log out'
                    },
                  ]}
                />
              ) :context.profileInfo.assigned_role === "trips" ? (
                <Menu
                  mode="inline"
                  onClick={(e) => {
                    handleMenuSelect(e.key)
                  }}
                  items={[
                    {
                      key: '14',
                      icon: <AiFillHdd />,
                      label: 'Home'
                    },
                    {
                      key: '11',
                      icon: <AiFillHome />,
                      label: 'Paid'
                    },
                    {
                      key: "10",
                      icon: <GiOpenBook />,
                      label: "Paid No Tickets",
                    },
                    {
                      key: "2",
                      icon: <FiSearch />,
                      label: "Today",
                    },
                    {
                      key: "5",
                      icon: <FaUsers />,
                      label: "Yesterday",
                    },
                    {
                      key: '4',
                      icon: <BsFillFolderFill />,
                      label: 'All Bookings'
                    }
                  ]}
                />
              ):(
                <Menu
                  mode="inline"
                  onClick={(e) => {
                    handleMenuSelect(e.key)
                  }}
                  items={[
                    {
                      key: '13',
                      icon: <BsFillFolderFill />,
                      label: 'Home'
                    },
                    {
                      key: '11',
                      icon: <AiFillHome />,
                      label: 'Paid'
                    },
                    {
                      key: '10',
                      icon: <TiUserAdd />,
                      label: 'Paid No Tickets'
                    },
                    {
                      key: '3',
                      icon: <BsFillFolderFill />,
                      label: 'Unconfirmed Bookings'
                    },
                    {
                      key: '4',
                      icon: <BsFillFolderFill />,
                      label: 'All Bookings'
                    },
                    {
                      key: '2',
                      icon: <FiSearch />,
                      label: 'Today'
                    },
                    {
                      key: '5',
                      icon: <FaUsers />,
                      label: 'Yesterday'
                    },
                    {
                      key: '7',
                      icon:  <FaSuitcase />,
                      label: 'All Time Analysis'
                    },
                    {
                      key: '6',
                      icon:  <IoMdExit />,
                      label: 'Log out'
                    },
                  ]}
                />
              )
            ) : (
              <span></span>
            )}
            <Settings theme={theme} />
        </Drawer>
      </div>
      <div className="header">
        <Text
          style={{
            color: `${
              theme === "Sierra"
                ? themeColorPalettes[theme].textColor
                : themeColorPalettes[theme].iconColor
            }`,
            fontSize:
              screenSize.dynamicWidth > 1010
                ? "25px"
                : screenSize.dynamicWidth > 360
                ? "15px"
                : screenSize.dynamicWidth > 240
                ? "10px"
                : "7px",
          }}
        >
          {contentForm === 1
            ? "Create New User"
            : contentForm === 2
            ? "Today"
            : contentForm === 3
            ? "Unconfirmed Bookings"
            : contentForm === 4
            ? "All Bookings"
            : contentForm === 5
            ? "Yesterday"
            : contentForm === 6
            ? "Profile"
            : contentForm === 7
            ? "All Time Bookings"
            : contentForm === 8
            ? "Roles"
            : contentForm === 9
            ? "Last Week"
            : contentForm === 10
            ? "Paid No Tickets"
            : contentForm === 12
            ? "Paid No PNRs"
            : contentForm === 13
            ? "Totals"
            : contentForm === 14
            ? "Trips Portal"
            : "Paid Bookings"}
        </Text>
      </div>

      {collapse || screenSize.dynamicWidth < 570 ? (
        context.profileInfo.assigned_role === "easycoach_user" ? (
          <img src={EasycoachLogo} alt="-logo" className="logo-img-sm" />
        ) : context.profileInfo.assigned_role === "smartcoach_user" ? (
          <img
            src={SmartcoachLogo}
            alt="-logo"
            className="square-logo-img-sm"
          />
        ) :
        context.profileInfo.assigned_role === "trips" ? (
          <img
            src={TripsLogo}
            alt="-logo"
            className="square-logo-img-sm"
          />
        ) : (
          <div className="logo" onClick={() => navigate("/")}>
            <Text
              style={{
                color: themeColorPalettes[theme].iconColor,
                fontSize:
                  screenSize.dynamicWidth > 730
                    ? "25px"
                    : screenSize.dynamicWidth > 277
                    ? "15px"
                    : "8px",
              }}
            >
              B
            </Text>
            <Text
              style={{
                color: themeColorPalettes[theme].iconColor,
                fontSize:
                  screenSize.dynamicWidth > 730
                    ? "25px"
                    : screenSize.dynamicWidth > 277
                    ? "15px"
                    : "8px",
              }}
            >
              P
            </Text>
          </div>
        )
      ) : context.profileInfo.assigned_role === "easycoach_user" ? (
        <img src={EasycoachLogo} alt="easycoach-logo" className="logo-img" />
      ) : context.profileInfo.assigned_role === "smartcoach_user" ? (
        <img src={SmartcoachLogo} alt="smart-logo" className="square-logo-img" />
      ) : context.profileInfo.assigned_role === "trips" ? (
        <div style={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '5px',
          marginLeft: '10px'
        }}>
          <img
            style={{
              padding: '5px',
            }}
            src={TripsLogo}
            alt="trips-logo"
            className="square-logo-img-sm"
          />
        </div>
      ) : (
        <div onClick={() => navigate("/")} className="logo">
          <Text
            style={{
              color: themeColorPalettes[theme].iconColor,
            }}
          >
            Buu
          </Text>
          <Text
            style={{
              color: themeColorPalettes[theme].iconColor,
            }}
          >
            Pass
          </Text>
        </div>
      )}
    </Header>
  );
};

export default HeaderComponent;
