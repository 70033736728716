import { css } from "@emotion/react";

export const paidBookingsCss = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 15px 0 0 2vw;
  width: 100%;
  overflow-x: hidden;
  & .ant-table-wrapper{
    width: 97%;
    & .ant-spin-nested-loading{
      & .ant-spin-container{
        position: relative;
        & .ant-table{
          & .ant-table-container{
            & .ant-table-header{
              font-size: 1em;
              & .ant-table-cell{
                border: none;
              }
            }
            & .ant-table-body{
              font-size: 0.8em;
              & .ant-table-tbody{
                & .ant-table-row{
                  & .ant-table-cell{
                    border: none;
                  }
                }
              }
            }
          }
        }
        & .ant-pagination{
          position: absolute;
          left: 25vw;
          & .ant-pagination-options{
            display: none;
          }
          & .ant-pagination-item{
            border-radius: 20px;
                background: whitesmoke;
                margin: 0 5px;
          }
          & .ant-pagination-item-active{
            border-radius: 20px;
            background: #4E4E50;
          }
          & .ant-pagination-prev{
            .ant-pagination-item-link{
              border-radius: 20px;
              background-color: #D79922;
            }
          }
          & .ant-pagination-next{
            .ant-pagination-item-link{
              border-radius: 20px;
              background-color: #D79922;
            }
          }
        }
      }
    }
  }
  & .mojave-table{
    & .ant-table-wrapper{
      & .ant-spin-nested-loading{
        & .ant-spin-container{
          & .ant-table{
            & .ant-table-container{
              & .ant-table-header{
                & .ant-table-cell{
                  color: #F13C20;// extraColor
                  background-color: #D79922; // sideBarColor
                }
                & .ant-table-cell-fix-left{
                  color: #C5CBE3; // iconColor
                  background-color: #D79922; // sideBarColor
                }
                & .ant-table-cell-fix-right-first{
                  color: #C5CBE3; // iconColor
                  background-color: #D79922; // sideBarColor
                }
              }
              & .ant-table-body{
                & .ant-table-tbody{
                  & .ant-table-row{
                    & .ant-table-cell{
                      background-color: #EFE2BA; // backgroundColor
                      color: white;
                    }
                    & .ant-table-cell-fix-left{
                      background-color: #4E4E50; // textColor
                    }
                    & .ant-table-cell-fix-right-first{
                      background-color: #6F2232;  // sideBarColorHover
                    }
                  }
                }
              }
            }
          }
          & .ant-pagination{
            & .ant-pagination-options{
              display: none;
            }
            & .ant-pagination-item{
              background-color: #C5CBE3;  // iconColor
              border: none;
            }
            & .ant-pagination-prev{
              & .ant-pagination-item-link{
                background-color: #F13C20;// extraColor
                border: none;
                color: white;
              }
            }
            & .ant-pagination-next{
              & .ant-pagination-item-link{
                background-color: #F13C20;// extraColor
                border: none;
                color: white;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    & .ant-table-wrapper{
      width: 95%;
      & .ant-spin-nested-loading{
        & .ant-spin-container{
          position: relative;
          & .ant-table{
            & .ant-table-container{
              & .ant-table-header{
                font-size: 1em;
                & .ant-table-cell{
                  border: none;
                }
              }
              & .ant-table-body{
                font-size: 0.9em;
                & .ant-table-tbody{
                  tr {
                    height: 70%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const actionBtnsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  & .action-button{
    border: 1px solid #42A29E;
    text-align: center;
    border-radius: 3px;
    width: 80%;
    cursor: pointer;
    font-weight: 500;
    margin: 4px 0;
    font-size: 12px;
    &:hover{
      background-color: #42A29E;
      color: white;
    }
  }
`

export const actionLightBtnsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  & .action-button{
    border: 1px solid #6F2232;
    text-align: center;
    border-radius: 3px;
    width: 80%;
    cursor: pointer;
    font-weight: 500;
    margin: 4px 0;
    font-size: 12px;
    &:hover{
      background-color: #6F2232;
      color: white;
    }
  }
`