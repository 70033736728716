import React, { useContext, useState } from "react";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import "./sider-main.styles.less";
import { FiSearch } from "react-icons/fi";
import { MdEventBusy } from "react-icons/md";
import { AiFillHdd, AiFillHome } from "react-icons/ai";
import { GiOpenBook } from "react-icons/gi";
import { BiArchive } from "react-icons/bi";
import { FaSuitcase, FaUsers } from "react-icons/fa";
import { contentPages, themeColorPalettes } from "../../utils/utilities";
import { BsFillFolderFill } from "react-icons/bs";
import AppContext from "../../utils/context";

const { Sider } = Layout;

interface ISideBarProps {
  setContentForm: (arg: number) => void;
  setCollapsed: (arg: boolean) => void;
  theme: string;
  collapse: boolean;
}

const SideBar: React.FC<ISideBarProps> = ({
  setContentForm,
  setCollapsed,
  theme,
  collapse,
}) => {
  const [selected, setSelected] = useState("6");
  const context = useContext(AppContext);
  const onCollapse = (collapse: boolean) => {
    setCollapsed(collapse);
  };

  const toggle = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setCollapsed(!collapse);
  };
  return (
    <Sider
      collapsible
      collapsed={collapse}
      onCollapse={onCollapse}
      trigger={null}
      style={{
        backgroundColor: themeColorPalettes[theme].sideBarColor,
        height: "100vh",
      }}
    >
      <div
        className={`
          style-menu
          ${theme === "Mojave" ? "mojave-theme" : ""}
        `}
      >
        <Menu
          theme="dark"
          defaultSelectedKeys={["sub4"]}
          defaultOpenKeys={[
            `${selected === "4" || selected === "5" ? "sub4" : ""}`,
          ]}
          mode="inline"
          style={{
            backgroundColor: themeColorPalettes[theme].sideBarColor,
            color: themeColorPalettes[theme].iconColor,
            // fontSize: screenSize.dynamicWidth < 1353 ? "8px" : "18px",
          }}
          onSelect={({ key }) => {
            setSelected(key);
            if (key === "11") {
              setContentForm(contentPages["paidBookings"]);
            }
            if (key === "14") {
              setContentForm(contentPages["tripsLanding"]);
            }
            if (key === "10") {
              setContentForm(contentPages["paidNoTickets"]);
            }
            if (key === "3") {
              setContentForm(contentPages["unconfirmedBookings"]);
            }
            if (key === "12") {
              setContentForm(contentPages["paidNoPnr"]);
            }
            if (key === "4") {
              setContentForm(contentPages["allBookings"]);
            }
            if (key === "2") {
              setContentForm(contentPages["todayBookings"]);
            }
            if (key === "5") {
              setContentForm(contentPages["yesterDayBookings"]);
            }
            if (key === "7") {
              setContentForm(contentPages["analysis"]);
            }
          }}
          items={
            context.profileInfo.assigned_role !== "trips" ? 
            [{
              key: "11",
              icon: <AiFillHome />,
              label: "Paid",
            },
            {
              key: "10",
              icon: <GiOpenBook />,

              label: "Paid No Tickets",
            },
            {
              key: "3",
              icon: <BsFillFolderFill />,
              label: "Unconfirmed",
            },
            {
              key: "12",
              icon: <MdEventBusy />,
              label: "No PNR",
            },
            {
              key: "4",
              icon: <BiArchive />,
              label: "All Bookings",
            },
            {
              key: "2",
              icon: <FiSearch />,
              label: "Today",
            },
            {
              key: "5",
              icon: <FaUsers />,
              label: "Yesterday",
            },
            {
              key: "7",
              icon: <FaSuitcase />,
              label: "All Time",
            },
          ]:[
            {
              key: '14',
              icon: <AiFillHdd />,
              label: 'Home'
            },
            {
              key: "11",
              icon: <AiFillHome />,
              label: "Paid",
            },
            {
              key: "10",
              icon: <GiOpenBook />,
              label: "Paid No Tickets",
            },
            {
              key: "2",
              icon: <FiSearch />,
              label: "Today",
            },
            {
              key: "5",
              icon: <FaUsers />,
              label: "Yesterday",
            },
            {
              key: "4",
              icon: <BiArchive />,
              label: "All Bookings",
            }
          ]}
        ></Menu>
      </div>
      {React.createElement(collapse ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: `trigger ${!collapse ? "" : "trigger-marginLeft-shrink"}`,
        onClick: toggle,
      })}
    </Sider>
  );
};

export default SideBar;
