/** @jsx jsx */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio';
import Utilities from '../../utils/utilities';
import AppContext from '../../utils/context';
import { css, jsx } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { IoMdExit } from 'react-icons/io';

const radioStyle = {
  // display: 'block',
  height: '30px',
  lineHeight: '30px',
};
interface ISettingProps {
  theme: string;
}
const Settings: React.FC<ISettingProps> = ({
  theme
}) => {

  const context = useContext(AppContext)
  const contextRef = useRef(context);
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    storedTheme? setValue(storedTheme):setValue('default');
    return () => {
      setValue('')
    }
  }, []);

  const onChange = (e: RadioChangeEvent): void => {
    const selectedTheme = e.target.value === 1? 'default': 'Sierra';
    console.log('radio checked', selectedTheme);
    setValue(selectedTheme);
     contextRef.current.toggleTheme(selectedTheme)
    localStorage.setItem('theme', selectedTheme);
  };

  return (
    <li css={css`
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: end;
    `}> 
      <div
        onClick={() => navigate("/login")}
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 70%;
          cursor: pointer;
        `}
      >
        <IoMdExit />
        <span
          css={css`
           margin-left: 10px;
         `}
        >Log Out</span>
      </div>
      <Radio.Group 
        onChange={onChange}
        value={Utilities.convertToInt(value)}
        buttonStyle='solid'  
      >
        <Radio 
          style={radioStyle} 
          value={1} 
          css={css`
            color: white;
          `}
        >
          Light
        </Radio>
        <Radio 
          style={radioStyle} 
          value={2}
          css={css`
            color: white;
          `}
        >
          Dark
        </Radio>
      </Radio.Group>
    </li>
  )
}

export default Settings
