import { css } from "@emotion/react";


export const analyticsCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1% 0 0 2vw;
  height: 85%;
  overflow-y: hidden;
  overflow-x: hidden;
  & .analytic-loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  & .analytics-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 98%;
    & .doughnut {
      & .light {
        background-color: rgb(250, 234, 240);
      }
      & .dark {
        background-color: rgb(26, 26, 28);
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      height: 40vh;
      margin: 0 5px 0 0;
      & .apexcharts-canvas {
        & .apexcharts-legend {
          & .apexcharts-legend-series {
            & .apexcharts-legend-text {
              color: aliceblue;
              font-size: larger;
            }
          }
        }
      }
    }
    & .bar {
      display: flex;
      flex-direction: column;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      & .light {
        background-color: rgb(250, 234, 240);
      }
      & .dark {
        background-color: rgb(26, 26, 28);
      }
    }
  }
`