import React from 'react'
import { layout } from '../add-users/add-users.component'
import { themeColorPalettes } from '../../utils/utilities'

import './update-profile.styles.less';
import { Form, Input, Button } from 'antd';
import { IProfile } from '../../utils/interfaces';

interface IUpdateProfileProps{
  theme: string;
  profile?: IProfile;
}

const UpdateProfile: React.FC<IUpdateProfileProps> = ({ theme, profile }) => {

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <div
      className="update-profile"
      style={{ 
        padding: 24,
        minHeight: 'auto',
        backgroundColor: themeColorPalettes[theme? theme: 'default'].textColor,
      }}
    >
      <Form 
        {...layout} 
        name="nest-messages" 
        onFinish={onFinish} 
        className={`${theme === 'default'? 'label-styling': ((theme === 'Mojave')? 'mojave-styling':'')}`}
      >
        <Form.Item 
          name='password'
          label="Password" 
          rules={[{ message: 'Please input your email!', }]}
          colon={false}
        >
          <Input placeholder='***'/>
        </Form.Item>
        <Form.Item 
          name='phone_number' 
          label="Phone Number" 
          rules={[{ message: 'Please input your phone number!' }]}
          colon={false}
        >
          <Input placeholder={profile?.phone_number}/>
        </Form.Item>
        <Form.Item 
          name='department' 
          label='Department' 
          rules={[{ message: 'Please input your department!' }]}
          colon={false}
        >
          <Input placeholder={profile?.department} />
        </Form.Item>
        <Form.Item 
          name='role' 
          label="Role" 
          rules={[{ message: 'Please input your role!' }]}
          colon={false}
        >
          <Input placeholder={profile?.assigned_role} />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button 
            htmlType="submit" style={{ 
            backgroundColor: themeColorPalettes[theme].extraColor
          }}>
            Update Info
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default UpdateProfile
